enum IntegrationFeatures {
  emailSentiment = 'Sentiment Analysis',
  benchmarkingSales = 'Benchmarking (Sales)',
  benchmarkingPM = 'Benchmarking (Property Management)',
  adGen = 'Ad Generator',
  automations = 'Automations',
  revenue = 'Revenue',
}

export type FieldErrorStatus = {
  error: boolean
  message?: string
}

export type IntegrationConfig = {
  use: integrationUseLevel
  integrationName: string
  logo: string
  primaryColor?: string
  features: IntegrationFeatures[]
  formFields: {
    apiKey: {
      value: string
      hide: boolean
    }
    apiSecret: {
      value: string
      hide: boolean
    }
    apiAccountKey: {
      value: string
      hide: boolean
    }
  }
}

export enum integrationUseLevel {
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  RESTRICTED = 'RESTRICTED',
  GLOBAL = 'GLOBAL',
}

export const integrationOptions: { [key: string]: IntegrationConfig } = {
  PME: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'PropertyMe',
    logo: 'logo-pme.png',
    primaryColor: '#2CC2E8',
    features: [
      IntegrationFeatures.benchmarkingPM,
      IntegrationFeatures.emailSentiment,
      IntegrationFeatures.adGen,
    ],
    formFields: {
      apiKey: { value: 'Client Id', hide: false },
      apiSecret: { value: 'Client Secret', hide: true },
      apiAccountKey: { value: 'Refresh Token', hide: true },
    },
  },
  VRE: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'VaultRE',
    logo: 'logo-vaultre.png',
    primaryColor: '#2c8ae8',
    features: [IntegrationFeatures.benchmarkingPM, IntegrationFeatures.adGen],
    formFields: {
      apiKey: { value: 'Vault Token', hide: true },
      apiSecret: { value: null, hide: true },
      apiAccountKey: { value: null, hide: true },
    },
  },
  REX: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'Rex Software',
    logo: 'logo-rex.png',
    primaryColor: '#213A6A',
    features: [
      IntegrationFeatures.benchmarkingSales,
      IntegrationFeatures.adGen,
    ],
    formFields: {
      apiKey: { value: 'Username', hide: false },
      apiSecret: { value: 'Password', hide: true },
      apiAccountKey: { value: 'Rex Account Id', hide: false },
    },
  },
  PT: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'Property Tree',
    logo: 'logo-propertytree.jpg',
    primaryColor: '#62B56D',
    features: [IntegrationFeatures.benchmarkingPM, IntegrationFeatures.adGen],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: 'Company Id', hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  HS: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'Hubspot',
    logo: 'logo-hubspot.jpg',
    primaryColor: '#FF5B35',
    features: [IntegrationFeatures.emailSentiment],
    formFields: {
      apiKey: { value: 'Access Token', hide: true },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  AGENTBOX: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'Agent Box',
    logo: 'logo-agentbox.jpg',
    primaryColor: '#98C2C1',
    features: [
      IntegrationFeatures.benchmarkingSales,
      IntegrationFeatures.adGen,
    ],
    formFields: {
      apiKey: { value: 'Account Id', hide: true },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: 'Account Key', hide: true },
    },
  },
  TIMEDOCTOR: {
    use: integrationUseLevel.HIDDEN,
    integrationName: 'Time Doctor',
    logo: 'logo-timedoctor.png',
    primaryColor: '#FF3B07',
    features: [],
    formFields: {
      apiKey: { value: 'Username', hide: false },
      apiSecret: { value: 'Password', hide: true },
      apiAccountKey: { value: 'Company Id', hide: false },
    },
  },
  BASEROW: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'Baserow',
    logo: 'logo-baserow.jpeg',
    primaryColor: '#5090F1',
    features: [IntegrationFeatures.automations],
    formFields: {
      apiKey: { value: 'Username', hide: false },
      apiSecret: { value: 'Password', hide: true },
      apiAccountKey: { value: null, hide: false },
    },
  },
  COMPARECONNECT: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'CompareConnect',
    logo: 'logo-compareconnect.png',
    primaryColor: '#41B56E',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  YOURPORTER: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'Your Porter',
    logo: 'logo-yourporter.png',
    primaryColor: '#4073B5',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  RAYWHITEHOMENOW: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'Ray White Home Now',
    logo: 'logo-homenow.png',
    primaryColor: '#F8D508',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  PRDCONNECT: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'PRD Connect',
    logo: 'logo-prdconnect.png',
    primaryColor: '#2CC2E8',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  THEWHOLEHOUSE: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'The Whole House',
    logo: 'logo-wholehouse.png',
    primaryColor: '#000000',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  RELLO: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'Rello',
    logo: 'logo-rello.jpeg',
    primaryColor: '#045D7B',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: 'Merchant Id', hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  ONCOVER: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'OnCover',
    logo: 'logo-oncover.webp',
    primaryColor: '#30839D',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  INNOVAYT: {
    use: integrationUseLevel.DISABLED,
    integrationName: 'Innovate Finance',
    logo: 'logo-innovate.jpeg',
    primaryColor: '#92CB56',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: null, hide: false },
      apiAccountKey: { value: null, hide: false },
    },
  },
  SNUG: {
    use: integrationUseLevel.GLOBAL,
    integrationName: 'SNUG',
    logo: 'logo-snug.png',
    primaryColor: '#EB4967',
    features: [IntegrationFeatures.revenue],
    formFields: {
      apiKey: { value: null, hide: false },
      apiSecret: { value: 'Office Id', hide: true },
      apiAccountKey: { value: null, hide: false },
    },
  },
}

export type IntegrationType = keyof typeof integrationOptions
